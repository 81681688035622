import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"
import FullWidthCTA from "../components/fullWidthCTABlock"
import SectionTitle from "../components/titleBlock"
import CallUs from "../components/callUsStrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from "../components/footerContactBlock"
import UniformCityLogo from "../images/uniformcity.svg"

const UniformCity = ({ data }) => {
  ///page details
  const pageDataEdges = data.allPageuniformcityJson.edges[0].node;
  return (
    <Layout>
      <Seo
        title="One Stop Shop For Uniform Supply And Management"
        description="We have automated the uniform ordering process, making it fast, easy and convenient to get everything you need in just a few clicks. Work with us now >>"
      />
      <TitleBlock
        pageTitle={'<span style="display:none;">Uniformcity</span><img style="height:2.5rem;" src='+  UniformCityLogo +' alt="Uniformcity Logo" />'}
        pageSubTitle={pageDataEdges.pageSubTitle}
        pageTitleBody={pageDataEdges.pageTitleBody}
        img={pageDataEdges.pageTitleImg}
      />
      <FullWidthCTA
        lsH3={'You’ll already know that uniform management is time-consuming… kitting out new staff, replacing existing branding, investing in seasonal attire, it’s a full-time job. '}
        lsBody={'That’s why we created Uniformcity, to remove the stress and offer a seamless, fast, reliable service. Plus, the entire platform is supported and provided by our established, long-standing company TKing Associates.'}
        rsH3={'It couldn’t be easier <span style="font-size:1.2em;vertical-align: middle;">☺</span>'}
        rsBody={'Everything has been developed to make the ordering process fast, simple and secure. And everything is transparent, so you know where you are at every stage.'}
        callUsStrapline={'Interested in Uniform Management?'}
      />
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 col-md-6">
              <SectionTitle title="Uniform management"></SectionTitle>
              <p className="fw-light fs-2 mb-5">
                Are you searching for someone to manage all your uniform requirements? 
              </p>
          </div>
         
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-6 ">
              <p className="fw-light fs-4 mb-4">
                Look no further…
              </p>
          </div>
          <div className="col-12 col-md-6 d-flex flex-column justify-content-end">

          </div>
        </div>
      </div>

       <div className="container">

        <div
          className="row mb-5"
          
        >
          <div
            className="col-12 col-md-6 "
            data-sal-duration="500"
            data-sal="slide-right"
            data-sal-delay="200"
            data-sal-easing="ease"
          >
              <video id="theVideo" className="w-100" preload="yes"  controls  muted    >
                <source src={'https://tkingfiles.fra1.digitaloceanspaces.com/2022_videos/uniform_city_how_to_use_the_web_portal_v01.mp4'} type="video/mp4" />
            </video>
          </div>
          <div
            className="col-12 col-md-6  d-flex flex-column justify-content-start"
            data-sal-duration="500"
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
          >
            <p className="fw-light fs-4 mb-3">
                Our bespoke uniform & workwear  system is tailored to your product requirements and identity.
              </p>
              <p className="fw-light fs-4 mt-3 mb-3 mt-md-0">
              <img
                style={{
                  height:'1.5rem',
                  }}
                src={UniformCityLogo}
                alt="Uniformcity Logo" /> your destination for seamless, flexible uniform management brought to you by TKing Associates.
            </p>
            <div className="row">
              <div className="col-12 col-md-6 my-3 my-md-0">
                <div className="d-grid gap-2">
                  <Link to="/contact" className="btn btn-lg btn-primary rounded-0" href="/contact">Get in touch</Link>
                  </div>
              </div>

              <div className="col  align-middle d-flex align-items-center">
                  <CallUs
                      accent={'secondary'}
                  />
              </div>
            </div>
          </div>
        </div>

        <div
          className="row mb-5"
          
        >
          <div
            className="col-12 col-md-6 "
            data-sal-duration="500"
            data-sal="slide-right"
            data-sal-delay="200"
            data-sal-easing="ease"
          >
              <StaticImage 
                  src="../images/TKIng-16.jpg"
                  quality={85}
                  formats={["AUTO", "WEBP"]}
                  alt="You don’t have to hold stock."
                  placeholder="blurred"
                  objectFit="cover"
                  layout="fullWidth"
                  className=""
              />
          </div>
          <div
            className="col-12 col-md-6 d-flex flex-column justify-content-center"
            data-sal-duration="500"
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
          >
            <p className="fw-normal fs-4  mt-3 mb-3 mt-md-0">
                Remember…
            </p>
              <p className="fw-light fs-2 mb-3">
                You don’t have to hold stock.
            </p>
            <ul >
              <li>Don’t have a back room for all those boxes? Not a problem. </li>
              <li>We produce on demand and manage your supply.</li>
            </ul>
          </div>
        </div>

        <div
          className="row mb-5"
          data-sal-duration="500"
          data-sal="slide-right"
          data-sal-delay="200"
          data-sal-easing="ease"
        >
          <div className="col-12 col-md-6">
              <StaticImage 
                  src="../images/TKIng-15.jpg"
                  quality={85}
                  formats={["AUTO", "WEBP"]}
                  alt="You don’t have to hold stock."
                  placeholder="blurred"
                  objectFit="cover"
                  layout="fullWidth"
                  className=""
              />
          </div>
          <div
            className="col-12 col-md-6 d-flex flex-column justify-content-center"
            data-sal-duration="500"
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
          >

              <p className="fw-light fs-2  mt-3 mb-3 mt-md-0">
                Order only what you need when you need it. 
            </p>
            <ul >
              <li>No minimum order quantity</li>
              <li>Rapid delivery</li>
              <li>Free carriage over £75 </li>
            </ul>
          </div>
        </div>

        <div
          className="row mb-5"
          data-sal-duration="500"
          data-sal="slide-right"
          data-sal-delay="200"
          data-sal-easing="ease"
        >
          <div className="col-12 col-md-6">
              <StaticImage 
                  src="../images/headers/compressed/TKing_uniformcity2.jpg"
                  quality={85}
                  formats={["AUTO", "WEBP"]}
                  alt="You don’t have to hold stock."
                  placeholder="blurred"
                  objectFit="cover"
                  layout="fullWidth"
                  className=""
              />
          </div>
          <div
            className="col-12 col-md-6 d-flex flex-column justify-content-center"
            data-sal-duration="500"
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
          >
              <p className="fw-light fs-2  mt-3 mb-3 mt-md-0">
                Remove the hassle of paperwork
            </p>
            <ul >
              <li>Track everything on your personalised online portal 24/7</li>
              <li>All-digital platform removes the hassle of paperwork</li>
            </ul>
          </div>
        </div>

      </div>
      <ContactForm /> 
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
    
    allPageuniformcityJson {
      edges {
        node {
          pageTitleBody
          pageTitle
          pageSubTitle
          pageTitleImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
        }
      }
    }
}`
export default UniformCity
